<main *transloco="let t;">
  <header class="splash">
    <div class="background-image">
      <img src="assets/img/220207_sharelog_aktienbuch_header.jpg" class="mobile-hidden" alt="" />
      <img src="assets/img/220207_sharelog_aktienbuch_header_mobile.jpg" class="desktop-hidden" alt="" />
    </div>
    <div class="text">
      <h1>{{ t('aktienbuch.title') }}</h1>
      <h2>{{ t('aktienbuch.subtitle') }}</h2>
    </div>
  </header>
  <section>
    <div class="col">
      <div class="text">
        <p>
          {{ t('aktienbuch.intro.paragraph1') }}
        </p>
        <p>
          {{ t('software.cockpit.paragraph2') }}
        </p>
      </div>
    </div>
    <div class="col">
      <div class="image">
        <img src="assets/img/segetis_software_sharelog_cockpit.jpg" alt="">
      </div>
    </div>


  </section>
  <section class="banner">
    <div class="text">
      <p>
        {{ t('netvote.contact.cta') }}
        <a href="tel:+41 41 541 91 09">+41 41 541 91 09</a>
        <span class="nobreak">{{ t('netvote.contact.hours') }}</span
        ><br />-<br />{{ t('netvote.contact.alternative') }}
        <a href="mailto:info@aktienbuch.ch">info@aktienbuch.ch</a>
      </p>
    </div>
  </section>

</main>
